<template>
  <v-container>
    <v-toolbar class="mb-3" rounded>
      <v-toolbar-title>{{$t('message.shop-detail-title')}}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <router-link :to="'/editShop/'+detailShop.id">
        <v-btn rounded color="primary">
          <v-icon left>
            mdi-pencil
          </v-icon>
          {{$t('message.edit-shop')}}
        </v-btn>
      </router-link>
    </v-toolbar>

    <v-card>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.id')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.id }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.store-name')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.store_name }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.postal-code')}}</span>
            </v-col>
            <v-col>
              <span class="label">〒{{ formatPostalcode(detailShop.postal_code) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.prefecture')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.prefecture }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.city')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.city }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.district')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.district }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.address')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.store_address }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4">
              <span class="label-title">{{ $t("message.phone") }}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.phone }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.contact')}}</span>
            </v-col>
            <v-col>
              <v-row style="margin-left: -130px">
                <v-col cols="1" md="1" xs="1" class="ms-5 nested-label-col-1" style="width: 100px">
                  <span class="label-title">{{$t('message.phone-number')}}</span>
                </v-col>
                <v-col>
                  <span class="label">-</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="1" md="1" xs="1" class="ms-5 nested-label-col-1" style="width: 100px">
                  <span class="label-title">{{$t('message.email-address')}}</span>
                </v-col>
                <v-col>
                  <span class="label">-</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.open-day')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.active_days }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.open-hour')}} | {{$t('message.close-hour')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ detailShop.open_hour }} ~ {{ detailShop.close_hour }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.image')}}</span>
            </v-col>
            <v-col>
              <v-img
                :lazy-src="detailShop.image_url"
                :src="detailShop.image_url"
                max-height="150"
                max-width="180px"
                ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4">
              <span class="label-title">{{ this.$t("message.store-image") }}</span>
            </v-col>
            <v-col>
              <template>
                <!-- <v-container class=""> -->
                  <v-row class="mb-6" no-gutters>
                      <v-col v-for="(img, i) in detailShop.raw_header_images" :key="i">
                          <v-img
                              :lazy-src="img.image_url"
                              :src="img.image_url"
                              max-height="150px"
                              max-width="180px"
                              class=""
                          ></v-img>
                      </v-col>
                  </v-row>
                <!-- </v-container> -->
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-row>
            <v-col class="ms-5" md="4" >
              <span class="label-title">{{$t('message.status')}}</span>
            </v-col>
            <v-col>
              <span class="label">{{ is_active }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <div class="d-flex flex-row-reverse mt-4 me-0" tile>
      <div class="m-2">
        <v-btn color="grey" @click="$router.go(-1)">
            {{$t('message.back-button')}}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ShopDetail",
  data() {
    return {
      
    };
  },
  computed: {
    is_active() {
      let cekIsStatus = this.$store.state.shop_module.shopDetail.is_active
      if(cekIsStatus == 1) {
        return this.$t("message.active")
      } else {
        return this.$t("message.inactive")
      }
    },
    detailShop() {
      return this.$store.state.shop_module.shopDetail
    }
  },
  methods: {
    formatPostalcode(postalcode) {
      if(postalcode != null && postalcode != undefined) {
        let convertStr = postalcode.toString()
        let newPostcode = convertStr.slice(0, 3)+'-'+convertStr.slice(3)
        return newPostcode
      }
    },
    dispatchShopDetail() {
      let id = this.$route.params.id
      return this.$store.dispatch('shop_module/fetchShopDetail', id)
    }
  },
  created() {
    this.dispatchShopDetail()
  }
};
</script>

<style scoped>
.label {
  text-transform: capitalize;
}
.label-title {
  text-transform: capitalize;
  color: #3F51B5;
  font-weight: bold;
}
</style>
